import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Fade } from "react-awesome-reveal";
import HelixIcon from "../components/helixIcon";
import WarpIcon from "../components/warpIcon";
import Hero from "../components/hero";
import GeneralSettings from "../../content/settings/general.yml"

const ContactUs = ({ data }) => {

  const heroContent = data.markdownRemark.frontmatter.hero;
  const html = data.markdownRemark.html

  const SocialMediaLinks = () => {
    if (GeneralSettings.facebook_link || GeneralSettings.instagram_link || GeneralSettings.linkedin_link) {
      return (
        <ul className="social-icons-list">
          {GeneralSettings.facebook_link &&
            <li className="social-icons"><a target="_blank" rel="noreferrer" href={GeneralSettings.facebook_link} aria-label="Facebook Link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#000000" d="M6.02293,16L6,9H3V6h3V4c0-2.6992,1.67151-4,4.07938-4c1.15339,0,2.14468,0.08587,2.43356,0.12425v2.82082 l-1.66998,0.00076c-1.30953,0-1.56309,0.62227-1.56309,1.53541V6H13l-1,3H9.27986v7H6.02293z"></path></svg></a></li>
          }
          {GeneralSettings.instagram_link &&
            <li className="social-icons"><a target="_blank" rel="noreferrer" href={GeneralSettings.instagram_link} aria-label="Instagram Link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8,1.441c2.136,0,2.389.009,3.233.047a4.419,4.419,0,0,1,1.485.276,2.472,2.472,0,0,1,.92.6,2.472,2.472,0,0,1,.6.92,4.419,4.419,0,0,1,.276,1.485c.038.844.047,1.1.047,3.233s-.009,2.389-.047,3.233a4.419,4.419,0,0,1-.276,1.485,2.644,2.644,0,0,1-1.518,1.518,4.419,4.419,0,0,1-1.485.276c-.844.038-1.1.047-3.233.047s-2.389-.009-3.233-.047a4.419,4.419,0,0,1-1.485-.276,2.472,2.472,0,0,1-.92-.6,2.472,2.472,0,0,1-.6-.92,4.419,4.419,0,0,1-.276-1.485c-.038-.844-.047-1.1-.047-3.233s.009-2.389.047-3.233a4.419,4.419,0,0,1,.276-1.485,2.472,2.472,0,0,1,.6-.92,2.472,2.472,0,0,1,.92-.6,4.419,4.419,0,0,1,1.485-.276c.844-.038,1.1-.047,3.233-.047M8,0C5.827,0,5.555.009,4.7.048A5.868,5.868,0,0,0,2.76.42a3.908,3.908,0,0,0-1.417.923A3.908,3.908,0,0,0,.42,2.76,5.868,5.868,0,0,0,.048,4.7C.009,5.555,0,5.827,0,8s.009,2.445.048,3.3A5.868,5.868,0,0,0,.42,13.24a3.908,3.908,0,0,0,.923,1.417,3.908,3.908,0,0,0,1.417.923,5.868,5.868,0,0,0,1.942.372C5.555,15.991,5.827,16,8,16s2.445-.009,3.3-.048a5.868,5.868,0,0,0,1.942-.372,4.094,4.094,0,0,0,2.34-2.34,5.868,5.868,0,0,0,.372-1.942c.039-.853.048-1.125.048-3.3s-.009-2.445-.048-3.3A5.868,5.868,0,0,0,15.58,2.76a3.908,3.908,0,0,0-.923-1.417A3.908,3.908,0,0,0,13.24.42,5.868,5.868,0,0,0,11.3.048C10.445.009,10.173,0,8,0Z" fill="#000000"></path><path d="M8,3.892A4.108,4.108,0,1,0,12.108,8,4.108,4.108,0,0,0,8,3.892Zm0,6.775A2.667,2.667,0,1,1,10.667,8,2.667,2.667,0,0,1,8,10.667Z" fill="#000000"></path><circle cx="12.27" cy="3.73" r="0.96" fill="#000000"></circle></svg></a></li>
          }
          {GeneralSettings.linkedin_link &&
            <li className="social-icons"><a target="_blank" rel="noreferrer" href={GeneralSettings.linkedin_link} aria-label="LinkedIn Link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#000000" d="M15.3,0H0.7C0.3,0,0,0.3,0,0.7v14.7C0,15.7,0.3,16,0.7,16h14.7c0.4,0,0.7-0.3,0.7-0.7V0.7 C16,0.3,15.7,0,15.3,0z M4.7,13.6H2.4V6h2.4V13.6z M3.6,5C2.8,5,2.2,4.3,2.2,3.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4 C4.9,4.3,4.3,5,3.6,5z M13.6,13.6h-2.4V9.9c0-0.9,0-2-1.2-2c-1.2,0-1.4,1-1.4,2v3.8H6.2V6h2.3v1h0c0.3-0.6,1.1-1.2,2.2-1.2 c2.4,0,2.8,1.6,2.8,3.6V13.6z"></path></svg></a></li>
          }
        </ul>
      )
    }
    return null
  }

  return (
    <Layout classes="contact-us">
      <Seo title={heroContent.title} description="Reach out to Law In Distress" />
      <Hero heroContent={heroContent} />
      <section className="article-content">
        <HelixIcon />
        <WarpIcon />
        <div className="container">
          <Fade triggerOnce>
            <section dangerouslySetInnerHTML={{ __html: html }} />
            <SocialMediaLinks />
          </Fade>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "contact-us"}}) {
    html
    frontmatter {
      title
      hero {
        small_title
        title
        caption
      }
    }
  }
}
`

export default ContactUs
